// WRITE ARTICLE
import { ShareIcon } from "@heroicons/vue/20/solid";

export const shareDatapoints = {
  order: 0,
  name: "Share datapoints",
  subcategory: "Share datapoints",
  icon: ShareIcon,
  iconColor: "text-orange-500",
  markdown: `# Share datapoints`,
};
